import React from "react";

export default function About() {
  return (
    <div>
      <h2>About</h2>
      <p>
        This is the about page. You can edit this in{" "}
        <code>src/pages/About.tsx</code>.
      </p>
    </div>
  );
}
